import { createSlice } from '@reduxjs/toolkit';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
    users: [],
    activeStep: 0,
    user: null,
    usersStatus: {
        loading: false,
        empty: false,
        error: null,
    },
    userStatus: {
        loading: false,
        error: null,
    },
};


const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // GET USERS
        getUsersStart(state) {
            state.usersStatus.loading = true;
            state.usersStatus.empty = false;
            state.usersStatus.error = null;
        },
        getUsersFailure(state, action) {
            state.usersStatus.loading = false;
            state.usersStatus.empty = false;
            state.usersStatus.error = action.payload;
        },
        getUsersSuccess(state, action) {
            const users = action.payload;
            state.users = users;
            state.usersStatus.loading = false;
            state.usersStatus.empty = !users.length;
            state.usersStatus.error = null;
        },
        backStep(state) {
            state.activeStep -= 1;
        },
        nextStep(state) {
            state.activeStep += 1;
        },

        gotoStep(state, action) {
            state.activeStep = action.payload;
        },

        // GET USER
        getUserStart(state) {
            state.userStatus.loading = true;
            state.userStatus.error = null;
        },
        getUserFailure(state, action) {
            state.userStatus.loading = false;
            state.userStatus.error = action.payload;
        },
        getUserSuccess(state, action) {
            state.user = action.payload;
            state.userStatus.loading = false;
            state.userStatus.error = null;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    getUsersStart,
    getUsersFailure,
    getUsersSuccess,
    getUserStart,
    getUserFailure,
    getUserSuccess,
    nextStep,
    backStep,
    gotoStep,
} = slice.actions;

// ----------------------------------------------------------------------

export function getUsers() {
    return async (dispatch) => {
        dispatch(getUsersStart());
        try {
            const response = await axios.get(API_ENDPOINTS.user.list);
            console.log("GET USERS", response.data.results);
            dispatch(getUsersSuccess(response.data.results));
        } catch (error) {
            dispatch(getUsersFailure(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getUser(userId) {
    return async (dispatch) => {
        dispatch(getUserStart());
        try {
            const response = await axios.get(`/api/users/${userId}`);
            dispatch(getUserSuccess(response.data));
        } catch (error) {
            dispatch(getUserFailure(error));
        }
    };
}

export function createUser(user) {
    return async (dispatch) => {
        dispatch(getUserStart());
        try {
            const response = await axios.post(API_ENDPOINTS.user.post, user);
            console.log("CREATE USER", response.data);
            dispatch(getUserSuccess(response.data));
            return response.data;
        } catch (error) {
            console.error(error);
            dispatch(getUserFailure(error));
            return error;
        }
    };
}