import { createSlice } from '@reduxjs/toolkit';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
    profile: null,
    profileStatus: {
        loading: false,
        error: null,
    },
};

const slice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        // GET PROFILE
        getProfileStart(state) {
            state.profileStatus.loading = true;
            state.profileStatus.error = null;
        },
        getProfileSuccess(state, action) {
            state.profile = action.payload;
            state.profileStatus.loading = false;
            state.profileStatus.error = null;
        },
        getProfileFailure(state, action) {
            state.profile = null;
            state.profileStatus.loading = false;
            state.profileStatus.error = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { getProfileStart, getProfileSuccess, getProfileFailure } = slice.actions;

// ----------------------------------------------------------------------

export function getProfile() {
    return async (dispatch) => {
        dispatch(getProfileStart());
        try {
            const response = await axios.get(API_ENDPOINTS.profile.me);
            console.log("GET PROFILE", response.data);
            dispatch(getProfileSuccess(response.data?.data));
        } catch (error) {
            dispatch(getProfileFailure(error.message));
        }
    };
}