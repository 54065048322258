import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import productReducer from './slices/product';
import customerReducer from './slices/customer';
import user from './slices/user';
import sale from './slices/sale';
import summary from './slices/summary';
import items_class from './slices/items_class';
import code_details from './slices/code_details';
import profile from './slices/profile';
import authentication from './slices/authentication';
// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['checkout'],
};

const customerPersistConfig = {
  key: 'customer',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['customers', 'customer'],
};

const userPersistConfig ={
  key: 'user',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['users', 'user'],
};

const salePersistConfig ={
  key: 'sale',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sales', 'sale', 'checkout'],
};

const sammaryPersistConfig ={
  key: 'summary',
  storage,
  keyPrefix: 'redux-',
  whitelist: [
    'customers',
    'insurances',
    'products',
    'sales',
    'users',
  ],
};

const itemsclassPersistConfig ={
  key: 'items_class',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['items_class'],
};

const codeDetailsPersistConfig ={
  key: 'code_details',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['code_details'],
};

const profilePersistConfig ={
  key: 'profile',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['profile'],
};

const authenticationPersistConfig ={
  key: 'authentication',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['loading', 'error'],
};

// ----------------------------------------------------------------------

export const rootReducer = combineReducers({
  product: persistReducer(productPersistConfig, productReducer),
  customer: persistReducer(customerPersistConfig, customerReducer),
  user: persistReducer(userPersistConfig, user),
  sale: persistReducer(salePersistConfig, sale),
  summary: persistReducer(sammaryPersistConfig, summary),
  items_class: persistReducer(itemsclassPersistConfig, items_class),
  code_details: persistReducer(codeDetailsPersistConfig, code_details),
  profile: persistReducer(profilePersistConfig, profile),
  authentication: persistReducer(authenticationPersistConfig, authentication),
});