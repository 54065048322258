import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  products: [],
  product: null,
  activeStep: 0,
  page: 0, // Add this to manage pagination
  rowsPerPage: 25, // Set the default rows per page
  totalItems: 0, // Track total items available
  productsStatus: {
    loading: false,
    empty: false,
    error: null,
  },
  productStatus: {
    loading: false,
    error: null,
  },
};


const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // GET PRODUCTS
    getProductsStart(state) {
      state.productsStatus.loading = true;
      state.productsStatus.empty = false;
      state.productsStatus.error = null;
    },
    getProductsFailure(state, action) {
      state.productsStatus.loading = false;
      state.productsStatus.empty = false;
      state.productsStatus.error = action.payload;
    },
    getProductsSuccess(state, action) {
      const { products, totalItems } = action.payload;
      state.products = products;
      state.totalItems = totalItems;
      state.productsStatus.loading = false;
      state.productsStatus.empty = !products.length;
      state.productsStatus.error = null;
    },

    backStep(state) {
      state.activeStep -= 1;
    },

    nextStep(state) {
      state.activeStep += 1;
    },

    gotoStep(state, action) {
      state.activeStep = action.payload;
    },

    // POST PRODUCT
    postProductStart(state) {
      state.productStatus.loading = true;
      state.productStatus.error = null;
    },

    postProductFailure(state, action) {
      state.productStatus.loading = false;
      state.productStatus.error = action.payload;
    },

    postProductSuccess(state, action) {
      const product = action.payload;
      state.product = product;
      state.productStatus.loading = false;
      state.productStatus.error = null;
      state.products = uniqBy([product, ...state.products], 'id');
      state.productsStatus.loading = false;
      state.productsStatus.empty = false;
      state.productsStatus.error = null;
    },

    setPage(state, action) {
      state.page = action.payload;
    },

    setRowsPerPage(state, action) {
      state.rowsPerPage = action.payload;
    },

    // Handle reset of pagination
    resetPagination(state) {
      state.page = 0;
      state.rowsPerPage = 10;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getProductsStart,
  getProductsFailure,
  getProductsSuccess,

  // POST PRODUCT
  postProductStart,
  postProductFailure,
  postProductSuccess,

  gotoStep,
  backStep,
  nextStep,
  setRowsPerPage,
  setPage,
  resetPagination,
} = slice.actions;

// ----------------------------------------------------------------------

export function getProducts(options = {}) {
  return async (dispatch, getState) => {
    const { page, rowsPerPage } = getState().product;
    
    dispatch(slice.actions.getProductsStart());
    
    try {
      const response = await axios.get(API_ENDPOINTS.product.list, {
        params: {
          limit: rowsPerPage,
          offset: page * rowsPerPage,
          ordering: '-id',
          ...options,
        },
      });

      console.log("GET PRODUCTS RESPONSE", response.data);

      // Assuming response.data contains total items in 'count'
      const { results, count } = response.data;

      dispatch(slice.actions.getProductsSuccess({
        products: results,
        totalItems: count,
      }));
    } catch (error) {
      dispatch(slice.actions.getProductsFailure(error.message || 'Error fetching products'));
    }
  };
}

// ----------------------------------------------------------------------

export function syncProductsFromEtims(lastReqDt) {
  return async (dispatch) => {
    dispatch(slice.actions.getProductsStart());
    try {
      const response = await axios.post(API_ENDPOINTS.product.sync, { lastReqDt });
      dispatch(slice.actions.getProductsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.getProductsFailure(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createProduct(product) {
  return async (dispatch) => {
    dispatch(slice.actions.postProductStart());
    try {
      const response = await axios.post(API_ENDPOINTS.product.post, product);
      console.log("POST PRODUCT RESPONSE", response.data);
      dispatch(slice.actions.postProductSuccess(response.data.product));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.postProductFailure(error));
      return error;
    }
  };
}

// ----------------------------------------------------------------------
