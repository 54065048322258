import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

export const API_ENDPOINTS = {
  auth: {
    me: '/api/authentication/me/',
    register: '/api/authentication/register/',
    login: '/api/authentication/login/',
    logout: '/api/authentication/logout/',
    changePassword: '/api/authentication/change-password/',
    forgotPassword: '/api/authentication/forgot-password/',
    resetPassword: '/api/authentication/reset-password/',
  },
  profile: {
    me: '/api/authentication/me/',
  },
  summary: {
    customers: '/api/summary/customers/',
    insurances: '/api/summary/insurances/',
    products: '/api/summary/products/',
    sales: '/api/summary/sales/',
    users: '/api/summary/users/'
  },
  product: {
    list: '/api/vscuProductManagement/select-items/',
    post: '/api/vscuProductManagement/save-item/',
    delete: (id) => `/api/vscuProductManagement/select-items/${id}/`,
    sync: '/api/vscuProductManagement/select-items/',
  },
  customer: {
    list: '/api/vscuBranchInformationManagement/branch-customers/',
    details: '/api/vscuBranchInformationManagement/branch-customers/',
    post: '/api/vscuBranchInformationManagement/branch-customers/',
    put: '/api/vscuBranchInformationManagement/branch-customers/',
    delete: '/api/vscuBranchInformationManagement/branch-customers/',
  },
  user: {
    list: '/api/vscuBranchInformationManagement/branch-users/',
    details: '/api/vscuBranchInformationManagement/branch-users/',
    post: '/api/vscuBranchInformationManagement/branch-users/',
    put: '/api/vscuBranchInformationManagement/branch-users/',
    delete: '/api/vscuBranchInformationManagement/branch-users/',
  },
  sale: {
    list: '/api/vscuSalesTransactionManagement/save-sale/',
    details: (id) => `/api/vscuSalesTransactionManagement/save-sale/${id}/`,
    post: '/api/vscuSalesTransactionManagement/save-sale/',
    delete: '/api/vscuSalesTransactionManagement/save-sale/',
    receipt: (id) => `/api/receipts/sale/${id}/`,
  },
  code: {
    list: '/api/vscuCodeDataManagement/codes/',
    post: '/api/vscuCodeDataManagement/codes/',
    details: '/api/vscuCodeDataManagement/codes/details/',
  },
  items_class: {
    list: '/api/vscuCodeDataManagement/items-class/',
    post: '/api/vscuCodeDataManagement/items-class/',
  },
  branch: {
    list: '/api/vscuCodeDataManagement/branches/',
    details: '/api/vscuCodeDataManagement/branches/',
    post: '/api/vscuCodeDataManagement/branches/',
  },
  notice: {
    list: '/api/vscuCodeDataManagement/notices/',
    details: '/api/vscuCodeDataManagement/notices/',
    post: '/api/vscuCodeDataManagement/notices/',
  },
};
