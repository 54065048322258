// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  POS: '/pos',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // DASHBOARD
  pos: ROOTS.POS,
  dashboard: {
    root: ROOTS.DASHBOARD,
    sales: {
      root: `${ROOTS.DASHBOARD}/sales`,
      newSale: `${ROOTS.DASHBOARD}/sales/new-sale`,
      salesList: `${ROOTS.DASHBOARD}/sales/sales-list`,
      saleDetails: (id) => `${ROOTS.DASHBOARD}/sales/${id}`,
    },
    products: {
      root: `${ROOTS.DASHBOARD}/products`,
      newProduct: `${ROOTS.DASHBOARD}/products/new-product`,
      productList: `${ROOTS.DASHBOARD}/products/product-list`,
    },
    purchase: {
      root: `${ROOTS.DASHBOARD}/purchase`,
      newPurchase: `${ROOTS.DASHBOARD}/purchase/new-purchase`,
      purchaseList: `${ROOTS.DASHBOARD}/purchase/purchase-list`,
    },
    stock: {
      root: `${ROOTS.DASHBOARD}/stock`,
      newStock: `${ROOTS.DASHBOARD}/stock/new-stock`,
      stockList: `${ROOTS.DASHBOARD}/stock/stock-list`,
    },
    report: {
      root: `${ROOTS.DASHBOARD}/report`,
      newReport: `${ROOTS.DASHBOARD}/report/new-report`,
      reportList: `${ROOTS.DASHBOARD}/report/report-list`,
    },
    people: {
      root: `${ROOTS.DASHBOARD}/people`,
      newCustomer: `${ROOTS.DASHBOARD}/people/new-customer`,
      customerList: `${ROOTS.DASHBOARD}/people/customer-list`,
      newSupplier: `${ROOTS.DASHBOARD}/people/new-supplier`,
      supplierList: `${ROOTS.DASHBOARD}/people/supplier-list`,
      newUser: `${ROOTS.DASHBOARD}/people/new-user`,
      userList: `${ROOTS.DASHBOARD}/people/user-list`,
    },
    settings: {
      root: `${ROOTS.DASHBOARD}/settings`,
      company: `${ROOTS.DASHBOARD}/settings/company`,
      profile: `${ROOTS.DASHBOARD}/settings/profile`,
      billing: `${ROOTS.DASHBOARD}/settings/billing`,
      integration: `${ROOTS.DASHBOARD}/settings/integration`,
      notification: `${ROOTS.DASHBOARD}/settings/notification`,
      security: `${ROOTS.DASHBOARD}/settings/security`,
      help: `${ROOTS.DASHBOARD}/settings/help`,
    },
  },
};
