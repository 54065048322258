import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { el } from 'date-fns/locale';

// ----------------------------------------------------------------------
// Dashboard pages
const IndexPage = lazy(() => import('src/pages/dashboard/dashboard'));

// Sales pages
const NewSale = lazy(() => import('src/pages/dashboard/sales/new-sale'));
const SalesList = lazy(() => import('src/pages/dashboard/sales/sales-list'));
const SaleDetails = lazy(() => import('src/pages/dashboard/sales/sale-details'));

// Products pages
const NewProduct = lazy(() => import('src/pages/dashboard/products/new-product'));
const ProductList = lazy(() => import('src/pages/dashboard/products/product-list'));

// Purchase pages
const NewPurchase = lazy(() => import('src/pages/dashboard/purchase/new-purchase'));
const PurchaseList = lazy(() => import('src/pages/dashboard/purchase/purchase-list'));

// Stock pages
const NewStock = lazy(() => import('src/pages/dashboard/stock/new-stock'));
const StockList = lazy(() => import('src/pages/dashboard/stock/stock-list'));

// People pages
const NewCustomer = lazy(() => import('src/pages/dashboard/people/new-customer'));
const CustomerList = lazy(() => import('src/pages/dashboard/people/customer-list'));
const NewSupplier = lazy(() => import('src/pages/dashboard/people/new-supplier'));
const SupplierList = lazy(() => import('src/pages/dashboard/people/supplier-list'));
const NewUser = lazy(() => import('src/pages/dashboard/people/new-user'));
const UserList = lazy(() => import('src/pages/dashboard/people/user-list'));

// Settings pages
const CompanySettings = lazy(() => import('src/pages/dashboard/settings/company'));
const ProfileSettings = lazy(() => import('src/pages/dashboard/settings/profile'));
const BillingSettings = lazy(() => import('src/pages/dashboard/settings/billing'));
const IntegrationSettings = lazy(() => import('src/pages/dashboard/settings/integration'));
const NotificationSettings = lazy(() => import('src/pages/dashboard/settings/notification'));
const SecuritySettings = lazy(() => import('src/pages/dashboard/settings/security'));
const HelpSettings = lazy(() => import('src/pages/dashboard/settings/help'));
// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      {
        path: 'sales',
        children: [
          { element: <SalesList />, index: true },
          { path: 'new-sale', element: <NewSale /> },
          { path: 'sales-list', element: <SalesList /> },
          { path: ':id', element: <SaleDetails /> },
        ],
      },
      {
        path: 'products',
        children: [
          { element: <ProductList />, index: true },
          { path: 'new-product', element: <NewProduct /> },
          { path: 'product-list', element: <ProductList /> },
        ],
      },
      {
        path: 'purchase',
        children: [
          { element: <PurchaseList />, index: true },
          { path: 'new-purchase', element: <NewPurchase /> },
          { path: 'purchase-list', element: <PurchaseList /> },
        ],
      },
      {
        path: 'stock',
        children: [
          { element: <StockList />, index: true },
          { path: 'new-stock', element: <NewStock /> },
          { path: 'stock-list', element: <StockList /> },
        ],
      },      
      {
        path: 'people',
        children: [
          { element: <CustomerList />, index: true },
          { path: 'new-customer', element: <NewCustomer /> },
          { path: 'customer-list', element: <CustomerList /> },
          { path: 'new-supplier', element: <NewSupplier /> },
          { path: 'supplier-list', element: <SupplierList /> },
          { path: 'new-user', element: <NewUser /> },
          { path: 'user-list', element: <UserList /> },
        ],
      },
      {
        path: 'settings',
        children: [
          { element: <CompanySettings />, index: true },
          { path: 'company', element: <CompanySettings /> },
          { path: 'profile', element: <ProfileSettings /> },
          { path: 'billing', element: <BillingSettings /> },
          { path: 'integration', element: <IntegrationSettings /> },
          { path: 'notification', element: <NotificationSettings /> },
          { path: 'security', element: <SecuritySettings /> },
          { path: 'help', element: <HelpSettings /> },
        ],
      },
    ],
  },
];
