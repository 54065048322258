import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { it } from 'date-fns/locale';

// ----------------------------------------------------------------------

const initialState = {
    items_class: [],
    items_classStatus: {
        loading: false,
        empty: false,
        error: null,
    },
};

const slice = createSlice({
    name: 'items_class',
    initialState,
    reducers: {
        // GET ITEMS_CLASS
        getItemsClassStart(state) {
            state.items_classStatus.loading = true;
            state.items_classStatus.empty = false;
            state.items_classStatus.error = null;
        },
        getItemsClassFailure(state, action) {
            state.items_classStatus.loading = false;
            state.items_classStatus.empty = false;
            state.items_classStatus.error = action.payload;
        },
        getItemsClassSuccess(state, action) {
            const items_class = action.payload;
            state.items_class = items_class;
            state.items_classStatus.loading = false;
            state.items_classStatus.empty = !items_class.length;
            state.items_classStatus.error = null;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { getItemsClassStart, getItemsClassFailure, getItemsClassSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getItemsClass(options = {}) {
    return async (dispatch) => {
        dispatch(getItemsClassStart());
        try {
            const response = await axios.get(API_ENDPOINTS.items_class.list, { 
                params: {
                    limit: 200,
                    offset: 0,
                    ...options,
                },
            });
            console.log("GET ITEMS_CLASS: response", response);
            dispatch(getItemsClassSuccess(response.data.results));
        } catch (error) {
            dispatch(getItemsClassFailure(error));
        }
    };
}

// ----------------------------------------------------------------------