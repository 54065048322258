import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  sales: [],
  sale: null,
  activeStep: 0,
  page: 0, // Add this to manage pagination
  rowsPerPage: 10, // Set the default rows per page
  totalItems: 0, // Track total items available
  salesStatus: {
    loading: false,
    empty: false,
    error: null,
  },
  saleStatus: {
    loading: false,
    error: null,
  },
  checkout: {
    branch_customer_id: '',
    select_items: [
      // {
      //     item_id: '',
      //     pkg: 1,
      //     qty: 1,
      //     price_group: 'dftPrc',
      //     dcAmt: 0,
      //     item: {},
      //     price: 0,
      //     total: 0,
      // }
    ],
    remark: '',
    transaction_type_id: '329'
  },
  heldOrders: [], // Add this line
};

// Helper function to calculate the total of an item
function calculateTotal(item) {
  return (item.qty * item.price) - item.dcAmt;
}

// Helper function to calculate the price of an item
function calculatePrice(item) {
  switch (item.price_group) {
    case 'grpPrcL1':
      return item.item.grpPrcL1 || item.item.dftPrc;
    case 'grpPrcL2':
      return item.item.grpPrcL2 || item.item.dftPrc;
    case 'grpPrcL3':
      return item.item.grpPrcL3 || item.item.dftPrc;
    case 'grpPrcL4':
      return item.item.grpPrcL4 || item.item.dftPrc;
    case 'grpPrcL5':
      return item.item.grpPrcL5 || item.item.dftPrc;
    default:
      return item.item.dftPrc;
  }
}

const slice = createSlice({
  name: 'sales',
  initialState,
  reducers: {
    // GET SALES
    getSalesStart(state) {
      state.salesStatus.loading = true;
      state.salesStatus.empty = false;
      state.salesStatus.error = null;
    },
    getSalesFailure(state, action) {
      state.salesStatus.loading = false;
      state.salesStatus.empty = false;
      state.salesStatus.error = action.payload;
    },
    getSalesSuccess(state, action) {
      const { sales, totalItems } = action.payload;

      state.sales = sales;

      state.salesStatus.loading = false;
      state.salesStatus.empty = !sales.length;
      state.salesStatus.error = null;
    },
    backStep(state, action) {
      state.activeStep -= 1;
    },

    nextStep(state, action) {
      state.activeStep += 1;
    },

    gotoStep(state, action) {
      state.activeStep = action.payload;
    },

    // GET SALE
    getSaleStart(state) {
      state.saleStatus.loading = true;
      state.saleStatus.error = null;
    },
    getSaleFailure(state, action) {
      state.saleStatus.loading = false;
      state.saleStatus.error = action.payload;
    },

    getSaleSuccess(state, action) {
      state.sale = action.payload;
      state.saleStatus.loading = false;
      state.saleStatus.error = null;
    },

    setPage(state, action) {
      state.page = action.payload;
    },

    setRowsPerPage(state, action) {
      state.rowsPerPage = action.payload;
    },

    resetPagination(state) {
      state.page = 0;
      state.rowsPerPage = 10;
    },

    // ADD ITEM
    addItem(state, action) {
      const existingItemIndex = state.checkout.select_items.findIndex(
        (item) => item.item_id === action.payload.item_id
      );

      if (existingItemIndex >= 0) {
        // If item exists, update its quantity and total
        const existingItem = state.checkout.select_items[existingItemIndex];
        existingItem.qty += action.payload.qty;
        existingItem.total = existingItem.qty * existingItem.price;
      } else {
        // If item does not exist, add new item with calculated total
        const newItem = {
          ...action.payload,
          price: action.payload.item.dftPrc,
          total: action.payload.qty * action.payload.item.dftPrc,
        };
        state.checkout.select_items.push(newItem);
      }
    },

    // UPDATE ITEM
    updateItem(state, action) {
      const { item } = action.payload;
      state.checkout.select_items = state.checkout.select_items.map((_item) =>
        _item.item_id === item.item_id ? { ..._item, ...item } : _item
      );
    },

    // REMOVE ITEM
    removeItem(state, action) {
      const { itemId } = action.payload;
      state.checkout.select_items = state.checkout.select_items.filter(
        (item) => item.item_id !== itemId
      );
    },

    // SET CUSTOMER
    setCustomer(state, action) {
      const { id } = action.payload;
      state.checkout.branch_customer_id = id;
    },

    // SET PAYMENT METHOD
    setPaymentMethod(state, action) {
      const { id } = action.payload;
      state.checkout.payment_method_id = id;
    },

    // SET TRANSACTION TYPE
    setTransactionType(state, action) {
      const { id } = action.payload;
      state.checkout.transaction_type_id = id;
    },

    // SET RECEIPT TYPE
    setReceiptType(state, action) {
      const { id } = action.payload;
      state.checkout.receipt_type_id = id;
    },

    // SET TRANSACTION PROGRESS
    setTransactionProgress(state, action) {
      const { id } = action.payload;
      state.checkout.transaction_progress_id = id;
    },

    // SET REMARK
    setRemark(state, action) {
      state.checkout.remark = action.payload;
    },

    // SET TRD INVC NO
    setTrdInvcNo(state, action) {
      state.checkout.trd_invc_no = action.payload;
    },

    // SET ORG INVC NO
    setOrgInvcNo(state, action) {
      state.checkout.org_invc_no = action.payload;
    },

    // SET DISCOUNT AMOUNT
    setDiscountAmount(state, action) {
      const { itemId, dcAmt } = action.payload;
      state.checkout.select_items = state.checkout.select_items.map((item) => {
        if (item.item_id === itemId) {
          const updatedItem = { ...item, dcAmt };
          updatedItem.price = calculatePrice(updatedItem);
          updatedItem.total = calculateTotal(updatedItem);
          return updatedItem;
        }
        return item;
      });
    },

    // SET QUANTITY
    setQuantity(state, action) {
      const { itemId, qty } = action.payload;
      state.checkout.select_items = state.checkout.select_items.map((item) => {
        if (item.item_id === itemId) {
          const updatedItem = { ...item, qty };
          updatedItem.price = calculatePrice(updatedItem);
          updatedItem.total = calculateTotal(updatedItem);
          return updatedItem;
        }
        return item;
      });
    },

    // SET PACKAGE
    setPackage(state, action) {
      const { itemId, pkg } = action.payload;
      state.checkout.select_items = state.checkout.select_items.map((item) => {
        if (item.item_id === itemId) {
          const updatedItem = { ...item, pkg };
          updatedItem.price = calculatePrice(updatedItem);
          updatedItem.total = calculateTotal(updatedItem);
          return updatedItem;
        }
        return item;
      });
    },

    // SET PRICE GROUP
    setPriceGroup(state, action) {
      const { itemId, priceGroup } = action.payload;
      state.checkout.select_items = state.checkout.select_items.map((item) => {
        if (item.item_id === itemId) {
          const updatedItem = { ...item, price_group: priceGroup };
          updatedItem.price = calculatePrice(updatedItem);
          updatedItem.total = calculateTotal(updatedItem);
          return updatedItem;
        }
        return item;
      });
    },

    // SET SELECTED ITEMS
    setSelectedItems(state, action) {
      state.checkout.select_items = action.payload;
    },

    // SET CHECKOUT
    setCheckout(state, action) {
      state.checkout = action.payload;
    },

    // CLEAR CHECKOUT
    clearCheckout(state) {
      state.checkout = initialState.checkout;
    },

    // HOLD ORDER
    setHoldOrder(state, action) {
      state.heldOrders.push(action.payload);
    },

    // CLEAR HELD ORDERS
    clearHeldOrders(state) {
      state.heldOrders = [];
    },

    // REMOVE HELD ORDER
    removeHeldOrder(state, action) {
      const { orderId } = action.payload;
      state.heldOrders = state.heldOrders.filter((order) => order.id !== orderId);
    },
  },
});

export default slice.reducer;

// ----------------------------------------------------------------------

// Actions
export const {
  getSalesStart,
  getSalesFailure,
  getSalesSuccess,
  backStep,
  nextStep,
  gotoStep,
  getSaleStart,
  getSaleFailure,
  getSaleSuccess,
  addItem,
  updateItem,
  removeItem,
  setCustomer,
  setPaymentMethod,
  setTransactionType,
  setReceiptType,
  setTransactionProgress,
  setRemark,
  setTrdInvcNo,
  setOrgInvcNo,
  setDiscountAmount,
  setQuantity,
  setPackage,
  setPriceGroup,
  setSelectedItems,
  setCheckout,
  clearCheckout,
  setHoldOrder,
  clearHeldOrders,
  removeHeldOrder,
  } = slice.actions;

// ----------------------------------------------------------------------

export function getSales(options = {}) {
  return async (dispatch, getState) => {
    const { page, rowsPerPage } = getState().sale;
    dispatch(getSalesStart());
    try {
      const response = await axios.get(API_ENDPOINTS.sale.list, {
        params: {
          limit: rowsPerPage,
          offset: page * rowsPerPage,
          ordering: '-id',
          ...options,
          ...(options.salesDt_after && {
            salesDt_after: new Date(options.salesDt_after).toISOString().split('T')[0],
          }),
          ...(options.salesDt_before && {
            salesDt_before: new Date(options.salesDt_before).toISOString().split('T')[0],
          }),
        },
      });
      console.log('GET SALES: response', response.data.results);
      const { results, count } = response.data;
      dispatch(
        slice.actions.getSalesSuccess({
          sales: results,
          totalItems: count,
        })
      );
    } catch (error) {
      dispatch(slice.actions.getSalesFailure(error.message || 'Error fetching sales'));
    }
  };
}

export function getSale(id) {
  return async (dispatch) => {
    dispatch(getSaleStart());
    try {
      const response = await axios.get(API_ENDPOINTS.sale.details(id));
      console.log('GET SALE: response', response.data);
      dispatch(getSaleSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(getSaleFailure(error.message));
      return error;
    }
  };
}

export function createSale(data) {
  return async (dispatch) => {
    dispatch(getSalesStart());
    try {
      const response = await axios.post(API_ENDPOINTS.sale.post, data);
      console.log('CREATE SALE: response', response.data);
      dispatch(getSales());
      return response.data;
    } catch (error) {
      dispatch(getSalesFailure(error.message));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function getSaleReceipt(id) {
  return async () => {
    try {
      const response = await axios.get(API_ENDPOINTS.sale.receipt(id));
      console.log('GET SALE RECEIPT: response', response.data);
      return response.data;
    } catch (error) {
      return error;
    }
  };
}