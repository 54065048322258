import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: 'Overview',
        items: [
          { title: 'Dashboard', path: paths.dashboard.root, icon: ICONS.dashboard },
          { title: 'POS', path: paths.pos, icon: ICONS.ecommerce },
        ],
      },

      // SALES MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: 'Sales Management',
        items: [
          {
            title: 'Sales',
            path: paths.dashboard.sales.root,
            icon: ICONS.user,
            children: [
              { title: 'New Sale', path: paths.dashboard.sales.newSale },
              { title: 'Sales List', path: paths.dashboard.sales.salesList },
            ],
          },
        ],
      },

      // PRODUCT MANAGEMENT
      {
        subheader: 'Product Management',
        items: [
          {
            title: 'Products',
            path: paths.dashboard.products.root,
            icon: ICONS.user,
            children: [
              { title: 'New Product', path: paths.dashboard.products.newProduct },
              { title: 'Product List', path: paths.dashboard.products.productList },
            ],  
          },
        ],
      },

      // PURCHASE MANAGEMENT
      // {
      //   subheader: 'Purchase Management',
      //   items: [
      //     {
      //       title: 'Purchases',
      //       path: paths.dashboard.purchase.root,
      //       icon: ICONS.user,
      //       children: [
      //         { title: 'New Purchase', path: paths.dashboard.purchase.newPurchase },
      //         { title: 'Purchase List', path: paths.dashboard.purchase.purchaseList },
      //       ],
      //     },
      //   ],
      // },

      // // STOCK MANAGEMENT
      // {
      //   subheader: 'Stock Management',
      //   items: [
      //     {
      //       title: 'Stocks',
      //       path: paths.dashboard.stock.root,
      //       icon: ICONS.user,
      //       children: [
      //         { title: 'New Stock', path: paths.dashboard.stock.newStock },
      //         { title: 'Stock List', path: paths.dashboard.stock.stockList },
      //       ],
      //     },
      //   ],
      // },

      // PEOPLE MANAGEMENT
      {
        subheader: 'People Management',
        items: [
          {
            title: 'People',
            path: paths.dashboard.people.root,
            icon: ICONS.user,
            children: [
              { title: 'New Customer', path: paths.dashboard.people.newCustomer },
              { title: 'Customer List', path: paths.dashboard.people.customerList },
              { title: 'New User', path: paths.dashboard.people.newUser },
              { title: 'User List', path: paths.dashboard.people.userList },
              // { title: 'New Supplier', path: paths.dashboard.people.newSupplier },
              // { title: 'Supplier List', path: paths.dashboard.people.supplierList },
            ],
          },
        ],
      },

      // SETTINGS
      // ----------------------------------------------------------------------
      {
        subheader: 'Settings',
        items: [
          {
            title: 'Settings',
            path: paths.dashboard.settings.root,
            icon: ICONS.user,
            children: [
              // { title: 'Company Settings', path: paths.dashboard.settings.company },
              { title: 'Profile Settings', path: paths.dashboard.settings.profile },
              // { title: 'Billing Settings', path: paths.dashboard.settings.billing },
              // { title: 'Integration Settings', path: paths.dashboard.settings.integration },
              // { title: 'Notification Settings', path: paths.dashboard.settings.notification },
              // { title: 'Security Settings', path: paths.dashboard.settings.security },
              // { title: 'Help', path: paths.dashboard.settings.help },
            ],
          },
        ],
      },
    ],
    []
  );

  return data;
}
